import ApplicationConfig from "../../../ApplicationConfig";
import * as apiHelper from "../../../helpers/apiHelpers";

export class ScreenRepository {

    async fetchList(eventUuid) {
        const response = await apiHelper.authenticatedFetch(
            ApplicationConfig.BACKEND_URL + '/screen/list?event_uuid=' + eventUuid,
            {}
        );

        const responseBody = await response.json();        
        return responseBody;
    }

    async editScreen(screen) {
        const postBody = {
            uuid: screen.uuid,            
            ...this.mapScreenToPostBody(screen)
        };

        const response = await apiHelper.authenticatedFetch(
            ApplicationConfig.BACKEND_URL + '/screen/edit', 
            {                
                method: 'POST',
                body: JSON.stringify(postBody),
            }
        );

        const responseBody = await response.json();
        console.log('Screen edit response body', responseBody);
        return responseBody;
    }

    async createScreen(eventUuid, screen) {        
        const postBody = {
            eventUuid: eventUuid,
            ...this.mapScreenToPostBody(screen),
        };

        const response = await apiHelper.authenticatedFetch(
            ApplicationConfig.BACKEND_URL + '/screen/create', 
            {
                headers: apiHelper.getAuthorizedDefaultHeaders(),
                method: 'POST',
                body: JSON.stringify(postBody),
            }
        );

        const responseBody = await response.json();
        return responseBody;
    }

    mapScreenToPostBody(screen) {
        return {
            title: screen.title,
            type: screen.type,            
            data: screen.data,
        }
    }
}