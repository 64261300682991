import { useState } from "react";
import { connect } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import ScreenComponent from "./ScreenComponent";
import { IconButton } from "@material-ui/core";
import MediaDialog from "../../media/component/MediaDialog";

const ScreenImageComponent = (props) => {
  const component = props.component;
  const [mediaKey, setMediaKey] = useState(component.media ? component.media.key : null);
  const [imageSource, setImageSource] = useState(component.media ? component.media.url : '');
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  
  const handleEditButtonPressed = () => {
    setMediaDialogOpen(true);
  }

  const handleMediaSelected = (media) => {
    props.onSave({ 
      ...component,
      media: {key: media.key},
    });
    
    setMediaKey(media.key);
    setImageSource(media.url);
  }
  
  return (
      <ScreenComponent className="screen-image-component" component={props.component} onDelete={props.onDelete} type="Image">
        <div className="screen-image-component-content">
          <img className="component-image" src={imageSource} alt={imageSource ? "Component image" : "No image uploaded yet"}/> 
          <IconButton component="span" onClick={(e) => handleEditButtonPressed()}>
              <EditIcon />
          </IconButton>
        </div>
        { mediaDialogOpen && <MediaDialog onClose={() => setMediaDialogOpen(false)} onConfirm={handleMediaSelected}/>}
      </ScreenComponent>
  );
}


const mapStateToProps = (state, ownProps) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ScreenImageComponent);