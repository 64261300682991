import { Button, FormGroup, TextField, FormControlLabel, Checkbox } from "@material-ui/core";
import { HexColorPicker } from "react-colorful";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

const CustomerForm = (props) => {
  const customer = props.initialData;
  const [title, setTitle] = useState(customer ? customer.title : '');
  const [accessCode, setAccessCode] = useState(customer ? customer.accessCode : '');
  const [languages, setLanguages] = useState(customer ? customer.availableLanguages : ["nl"]);
  const [primaryColor, setPrimaryColor] = useState(customer ? customer.colors.primaryColor : '#02989f');
  const [selectectedLogoDataUrl, setSelectedLogoDataUrl] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
    
  useEffect(() => {
    props.onDataEdited({
      title: title,
      accessCode: accessCode,
      colors: {
        primaryColor: primaryColor,
      },
      logoBase64: selectectedLogoDataUrl,
      availableLanguages: languages,
    });
  }, [title, accessCode, languages, primaryColor, selectectedLogoDataUrl]);

  const onFileSelected = (e) => {
      const selectedFile = e.target.files[0];
      setSelectedFileName(selectedFile.name);

      var reader = new FileReader();
      reader.onload = function() {
        setSelectedLogoDataUrl(reader.result);
      }
      reader.readAsDataURL(selectedFile);
  }

  
  //TODO: abstract colorpicker
  return (
    <div>
      <div className="form-image">
        { selectectedLogoDataUrl 
          ? <img alt="customer logo" src={selectectedLogoDataUrl}/>
          : (customer && customer.logoUrl) && <img alt="customer logo" src={customer.logoUrl}/>
        }
        <div className="form-image-controls">
          <Button variant="contained" component="label">Select logo<input type="file" onChange={onFileSelected} hidden/></Button>
          {selectedFileName && <p>{selectedFileName}</p>}
        </div>
      </div>
      <TextField className="form-textfield" fullWidth={true} onChange={(e) => setTitle(e.target.value)} label="Title" variant="filled" value={title}/>
      <TextField className="form-textfield" fullWidth={true} onChange={(e) => setAccessCode(e.target.value)} label="Access code" variant="filled" value={accessCode}/>
      <LanguageSelector languages={languages} onChange={setLanguages}/>            
      <TextField className="form-textfield" fullWidth={true} onChange={(e) => setPrimaryColor(e.target.value)} label="Primary color" variant="filled" value={primaryColor}/>
      <div className="form-color-picker">
        <HexColorPicker color={primaryColor} onChange={setPrimaryColor}/>      
        <div className="form-color-picker-color" style={{backgroundColor: primaryColor}}></div>
      </div>
    </div>
  )
}


const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerForm);

const LanguageSelector = (props) => {
  const [selectedLanguages, setSelectedLanguages] = useState(props.languages);

  useEffect(() => {    
    props.onChange(selectedLanguages);
  }, [selectedLanguages]);

  const isSelected = (language) => {
    return selectedLanguages.includes(language);
  };
  
  const handleChange = (e) => {
    const selectedLanguage = e.target.name;
    const checked = e.target.checked;
    if (checked) {
      setSelectedLanguages([...selectedLanguages, selectedLanguage]);
    } else {
      setSelectedLanguages(selectedLanguages.filter((language) => language !== selectedLanguage));
    }
  };

  return (
    <div className="form-language-picker">
      <p>Languages:</p>
      <FormGroup row>
        <FormControlLabel label="nl" control={<Checkbox color="primary" checked={isSelected("nl")} name="nl" onChange={handleChange}/>}/>
        <FormControlLabel label="fr" control={<Checkbox color="primary" checked={isSelected("fr")} name="fr" onChange={handleChange}/>}/>
        <FormControlLabel label="en" control={<Checkbox color="primary" checked={isSelected("en")} name="en" onChange={handleChange}/>}/>
      </FormGroup>
    </div>
  );
}