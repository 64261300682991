
import { Button, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { generateShortKey } from "../../../util/uniqueIdUtil";


const HintList = (props) => {

  const onAddHint = () => {
    props.onUpdate([
      ...props.hints,
      {
        key: generateShortKey(),
        minutesUntilUnlocked: 10,
        pointCost: 30,
        title: props.languages.reduce((map, language) => {          
          map[language] = 'Visible title (' + language + ')';
          return map;
        }, {}),
        text: props.languages.reduce((map, language) => {          
          map[language] = 'Real hidden text here (' + language + ')';
          return map;
        }, {}),
      }
    ]);
  }

  const updateHintLanguageDependentProperty = (hintToUpdate, property, value) => {
    var languageDependentProperty = {...hintToUpdate[property]};
    languageDependentProperty[props.language] = value;

    updateHintProperty(hintToUpdate, property, languageDependentProperty);
  }

  const updateHintProperty = (hintToUpdate, property, value) => {
    const updatedHints = props.hints.map((hint) => {
      if (hint.key === hintToUpdate.key) {
        var updatedHint = {...hintToUpdate};
        updatedHint[property] = value;

        return updatedHint;
      } else {
        return hint;
      }
    });

    props.onUpdate(updatedHints);
  }

  return (
    <div className="hint-list">
      <h3>Hints:</h3>
      <div className="hint-list-item-wrapper">
        {
          props.hints.map((hint) => {
            return (
              <div key={hint.key} className="hint-list-item">
                <TextField 
                  className="hint-textfield"
                  variant="filled" 
                  label="Title" 
                  value={hint.title[props.language]} 
                  onChange={(e) => updateHintLanguageDependentProperty(hint, 'title', e.target.value)} 
                  size="small" 
                />
                <TextField 
                  className="hint-textfield"
                  variant="filled" 
                  label="Hint text" 
                  value={hint.text[props.language]}
                  onChange={(e) => updateHintLanguageDependentProperty(hint, 'text', e.target.value)} 
                  size="small" 
                />
                <TextField 
                  className="hint-number"
                  variant="filled" 
                  label="Unlock (min)" 
                  value={hint.minutesUntilUnlocked} 
                  onChange={(e) => updateHintProperty(hint, 'minutesUntilUnlocked', parseInt(e.target.value))} 
                  size="small" 
                  type="number"
                />
                <TextField 
                  className="hint-number"
                  variant="filled" 
                  label="Cost" 
                  value={hint.pointCost} 
                  onChange={(e) => updateHintProperty(hint, 'pointCost', parseInt(e.target.value))} 
                  size="small" 
                  type="number"/>
              </div>
            );
          })
        }
      </div>
      <Button variant="contained" color="primary" onClick={(e) => onAddHint()}>Add hint</Button>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HintList);

