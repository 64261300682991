
import { IconButton, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import ScreenComponent from "./ScreenComponent";

const ScreenTitleComponent = (props) => {
  const component = props.component;
  const language = props.language;  
  const [titleText, setTitleText] = useState('');
  const [isEditing, setIsEditing] = useState(component.text ? false : true);

  useEffect(() => {
    setTitleText(component.text ? (component.text[language] ? component.text[language] : '' ) : '');
  }, [props.language]);
  
  const onConfirm = () => {
    var updatedText = {
      ...(component.text ? component.text : {})
    };
    updatedText[language] = titleText;

    props.onSave({
      ...component,
      text: updatedText,
    });
    setIsEditing(false);
  }

  //TODO: title type
  return (
      <ScreenComponent className="screen-title-component" component={props.component} onDelete={props.onDelete} type="Title">
      { isEditing 
        ? <TextField variant="outlined" label="Title" value={titleText} onChange={(e) => setTitleText(e.target.value)} onKeyPress={(e) => {if(e.key === 'Enter') onConfirm()}} autoFocus/>
        : <div className="screen-title-component-content">
            <p>Content: {titleText}</p> 
            <IconButton onClick={(e) => setIsEditing(true)}><EditIcon /></IconButton>
          </div>
      }
      </ScreenComponent>
  )

}


const mapStateToProps = (state, ownProps) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ScreenTitleComponent);