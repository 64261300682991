import { IconButton, Paper, TextField } from "@material-ui/core";
import { useState } from "react";
import { connect } from "react-redux";
import { RemoveCircleIcon } from "../../../../common/components/icons";
import { generateShortKey } from "../../../../util/uniqueIdUtil";

const NewPlayerList = (props) => {
  const [newPlayers, setNewPlayers] = useState([]);
  const [newPlayerName, setNewPlayerName] = useState('');

  const cancelNewPlayer = (playerToCancel) => {
    const updatedNewPlayers = newPlayers.filter((newPlayer) => newPlayer.localKey !== playerToCancel.localKey);
    setNewPlayers(updatedNewPlayers);
    props.onNewPlayersUpdated(updatedNewPlayers);
  }

  const onConfirmNewPlayerName = () => {
    const updatedNewPlayers = [
      ...newPlayers,
      {
        localKey: generateShortKey(),
        name: newPlayerName,
      }
    ];

    setNewPlayers(updatedNewPlayers);
    setNewPlayerName('');
    props.onNewPlayersUpdated(updatedNewPlayers);
  }

  return (    
    <Paper elevation={3} className="player-list-wrapper new-player-list">
      <h3>Add new players here:</h3>
      <div className="player-list">
        { newPlayers.map(newPlayer => {
            return <div key={newPlayer.localKey} className="player-list-item">
              <div className="player-list-item-contents">
                <span>{newPlayer.name}</span>
                <div className="player-controls">
                  <IconButton className="delete-button" onClick={(e) => cancelNewPlayer(newPlayer)}>
                    <RemoveCircleIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          })
        }
        <TextField 
          className="form-textfield" 
          variant="standard" 
          onChange={(e) => setNewPlayerName(e.target.value)}
          placeholder="New player name"
          value={newPlayerName} 
          onKeyPress={(e) => {if(e.key === 'Enter') onConfirmNewPlayerName()}}
          autoFocus
        />
      </div>
    </Paper>    
  )
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NewPlayerList);