import ApplicationConfig from "../../../ApplicationConfig";
import * as apiHelper from "../../../helpers/apiHelpers";

export class EventRepository {

    async fetchList(challengeUuid) {
        const response = await apiHelper.authenticatedFetch(
            ApplicationConfig.BACKEND_URL + '/event/list?challenge_uuid=' + challengeUuid,
            {}
        );

        const responseBody = await response.json();        
        return responseBody;
    }

    async editEvent(event) {
        const postBody = {
            uuid: event.uuid,
            ...this.mapEventToPostBody(event)
        };

        const response = await apiHelper.authenticatedFetch(
            ApplicationConfig.BACKEND_URL + '/event/edit', 
            {                
                method: 'POST',
                body: JSON.stringify(postBody),
            }
        );

        const responseBody = await response.json();
        return responseBody;
    }

    mapEventToPostBody(event) {
        return {
            title: event.title,
            weight: event.weight,
            duration: event.duration,
        }
    }

    async createEvent(challengeUuid, event) {
        console.log('event', event);
        const postBody = {
            challengeUuid: challengeUuid,
            ...this.mapEventToPostBody(event),
        };

        const response = await apiHelper.authenticatedFetch(
            ApplicationConfig.BACKEND_URL + '/event/create', 
            {
                headers: apiHelper.getAuthorizedDefaultHeaders(),
                method: 'POST',
                body: JSON.stringify(postBody),
            }
        );

        const responseBody = await response.json();
        return responseBody;
    }

    async resetEvent(event) {        
        const postBody = {
            uuid: event.uuid
        };

        const response = await apiHelper.authenticatedFetch(
            ApplicationConfig.BACKEND_URL + '/event/reset', 
            {
                headers: apiHelper.getAuthorizedDefaultHeaders(),
                method: 'POST',
                body: JSON.stringify(postBody),
            }
        );

        const responseBody = await response.json();
        return responseBody;
    }
}