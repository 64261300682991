import { Checkbox, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { connect } from "react-redux";

const EventDurationControl = (props) => {
  const handleCheckBoxChecked = (checked) => {
    props.onUpdate(checked ? 90 : null);
  }

  const handleEditDurationClicked = () => {
    const duration = parseInt(prompt('Select event duration', props.duration));
    if (duration) {
      props.onUpdate(duration);
    }
  }

  return (
    <div>
      <label>Timed:</label>
      <Checkbox 
        checked={props.duration != null} 
        onChange={(e) => handleCheckBoxChecked(e.target.checked)} 
        color="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      {props.duration && 
        <>
          <label>{props.duration} min</label>
          <IconButton onClick={(e) => handleEditDurationClicked()}><EditIcon/></IconButton>
        </>
      }
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({  
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(EventDurationControl);