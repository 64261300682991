import { Button, Dialog, DialogActions, DialogContent, Slide } from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AddIcon } from "../../../common/components/icons";
import { editMedia, fetchList, uploadImage } from "../redux/mediaOperations";
import MediaEditForm from "./MediaEditForm";
import MediaList from "./MediaList";


const MediaDialog = (props) => {
  const [mediaRequestState, setMediaRequestState] = useState({searchText: null, limit: 12, offset: null});
  const [mediaItems, setMediaItems] = useState(null);
  const [selectedMediaItem, setSelectedMediaItem] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    fetchMediaItems();
  }, [mediaRequestState]);

  const fetchMediaItems = () => {    
    props.fetchList(mediaRequestState).then(unwrapResult).then((items) => {      
      setMediaItems(items);
    }).catch((e) => {
      setErrorMessage('Error' + e.toString())
    });
  };

  const handleMediaFormSave = async (base64Data, tags) => {
    if (selectedMediaItem != null) {
      await props.editMedia({key: selectedMediaItem.key, tags: tags}).then(unwrapResult);
      fetchMediaItems();
    } else {
      await props.uploadImage({base64Data: base64Data, tags: tags}).then(unwrapResult);
      setMediaRequestState({
        ...mediaRequestState,
        offset: 0,
      });
    }
    setSelectedMediaItem(null);
  }

  return (
    <Dialog open={true} TransitionComponent={Transition} maxWidth={'md'} fullWidth={true}>
      <DialogContent>
        <div className="media-dialog dialog-content">
          <h2>{'Media'}</h2>
          <div className="media-manager">
            <MediaList 
              items={mediaItems}
              itemsPerPage={mediaRequestState.limit} 
              onRequestStateUpdated={setMediaRequestState}
              onMediaSelected={setSelectedMediaItem}
            />
            <MediaEditForm 
              media={selectedMediaItem}
              onSave={handleMediaFormSave}
            />
          </div>
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={(e) => {setSelectedMediaItem(null)}}>Add media</Button>
          {errorMessage != null && <p className="error dialog-error">{errorMessage}</p>}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => props.onClose()}>
          Cancel
        </Button>
        <Button onClick={(e) => {
          props.onConfirm(selectedMediaItem);
          props.onClose();
        }} disabled={selectedMediaItem === null}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = (state, ownProps) => ({
  
});

const mapDispatchToProps = (dispatch) => ({
  fetchList: (request) => dispatch(fetchList(request)),
  uploadImage: (data) => dispatch(uploadImage(data)),
  editMedia: (data) => dispatch(editMedia(data)),
});
 
export default connect(mapStateToProps, mapDispatchToProps)(MediaDialog);


