import { Button, IconButton, Paper, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import SettingsIcon from "@material-ui/icons/Settings";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import {  useState } from "react";
import { Link } from "react-router-dom";
import { setChallengeToCopyUuid } from "../redux/challengeSlice";

const ChallengeListItem = (props) => {
  const challenge = props.challenge;
  const [title, setTitle] = useState(props.challenge.title);
  const [isEditing, setIsEditing] = useState(props.isNew != null ? props.isNew : false);

  const saveTitle = () => {
    props.onSave({
      ...challenge,
      title: title,
    });

    setIsEditing(false);
  };

  const copyButtonClicked = () => {
    props.setChallengeToCopy(challenge);
  }

  const cancelButtonClicked = () => {
    props.cancelChallengeCopy();
  }

  return (
    <Paper elevation={2} className="challenge-list-item">
        { isEditing 
          ? <TextField placeholder={'Challenge title'} value={title} onChange={(e) => setTitle(e.target.value)} onKeyPress={(e) => {if(e.key === 'Enter') saveTitle()}} autoFocus/>
          : <div className="challenge-list-item-contents">
              <div>
                <strong className="text-content">{challenge.title}</strong>
                <IconButton color="primary" onClick={()=>{setIsEditing(true)}}><EditIcon/></IconButton>
              </div>
              <div className="challenge-list-item-actions">
                <div className="copy-button-wrapper">
                  {
                    props.challengeToCopyUuid === null 
                    ? <Button className="copy-button" color="primary" onClick={(e) => copyButtonClicked()}>Copy</Button>
                    : props.challengeToCopyUuid === challenge.uuid 
                      ? <Button className="copy-button" color="primary" onClick={(e) => cancelButtonClicked()}>Cancel copy</Button>
                      :  ''
                  }
                  
                </div>
                <span>{challenge.active ? "Active" : "Inactive"}</span>
                <IconButton color="primary" onClick={()=>{props.onActivate()}}>{challenge.active ? <ToggleOnIcon/> : <ToggleOffIcon/>}</IconButton>
                <Link to={'challenge/' + challenge.uuid}>
                  <IconButton color="primary" onClick={()=>{}}><SettingsIcon/></IconButton>
                </Link>
              </div>

            </div>
        }

        

    </Paper>
  )
}


const mapStateToProps = (state) => ({
  challengeToCopyUuid: state.challenge.challengeToCopyUuid,
});

const mapDispatchToProps = (dispatch) => ({
  setChallengeToCopy: (challenge) => dispatch(setChallengeToCopyUuid(challenge.uuid)),
  cancelChallengeCopy: () => dispatch(setChallengeToCopyUuid(null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeListItem);