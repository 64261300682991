import { createSlice } from "@reduxjs/toolkit";

export const eventSlice = createSlice({
  name: 'event',
  initialState: {
    currentChallengeUuid: null,
    currentEventList: [],
  },
  reducers: {
    clearCurrent: (state, action) => {
      state.currentEventList = [];
      state.currentChallengeUuid = null;
    },
    setListForChallenge: (state, action) => {      
      state.currentEventList = action.payload.list;
      state.currentChallengeUuid = action.payload.challengeUuid;
    },
    updateScreenListForEvent: (state, action) => {
      state.currentEventList = state.currentEventList.map((event) => {
        if (event.uuid === action.payload.eventUuid) {
          event.screens = action.payload.screens;
          return event;
        } else {
          return event;
        }
      });
    },
  }
});

export const { 
  setListForChallenge,
  clearCurrent,
  updateScreenListForEvent,
} = eventSlice.actions;

export default eventSlice.reducer;