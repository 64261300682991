
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { connect } from "react-redux";


const LanguagePicker = (props) => {
    return (
      <FormControl className="language-picker" variant="filled">
        <InputLabel>Language</InputLabel>
        <Select value={props.currentLanguage} onChange={(e) => props.onChange(e.target.value)}>
          {props.languages.map((language) => {
            return (
              <MenuItem key={language} value={language}>{language}</MenuItem>
            )
          })}
        </Select>
      </FormControl>
  );
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePicker);