import { createAsyncThunk } from "@reduxjs/toolkit";
import { ScreenRepository } from "../../screen/data/ScreenRepository";
import { EventRepository } from "../data/EventRepository";
import { setListForChallenge, updateScreenListForEvent } from "./eventSlice";

const eventRepository = new EventRepository();
const screenRepository = new ScreenRepository();

const fetchList = createAsyncThunk(
  'event/fetchList',
  async (challengeUuid, thunkApi) => {
    try {
      const eventList = await eventRepository.fetchList(challengeUuid);            
      thunkApi.dispatch(setListForChallenge({
        challengeUuid: challengeUuid,
        list: eventList,
      }));
      
      return eventList;
    } catch (error) {            
      return thunkApi.rejectWithValue(error);
    }
  }
)

const refreshScreensOfEvent = createAsyncThunk(
  'event/refreshScreensOfEvent',
  async (event, thunkApi) => {
    try {
      const screenList = await screenRepository.fetchList(event.uuid);
      thunkApi.dispatch(updateScreenListForEvent({
        eventUuid: event.uuid,
        screens: screenList,
      }));

      return screenList;
    } catch(error) {
      return thunkApi.rejectWithValue(error);
    }
    
  }
)

const editEvent = createAsyncThunk(
  'event/edit',
  async (event, thunkApi) => {
    try {
      const challengeUuid = thunkApi.getState().event.currentChallengeUuid;            
      const result = await eventRepository.editEvent(event);
      if (result.success) {
        await thunkApi.dispatch(fetchList(challengeUuid));
        return true;
      } else {
        return thunkApi.rejectWithValue(result.error_code);;
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

const createEvent = createAsyncThunk(
  'event/create',
  async (event, thunkApi) => {
    try {
      const challengeUuid = thunkApi.getState().event.currentChallengeUuid;
      const result = await eventRepository.createEvent(challengeUuid, event);

      if (result.success) {
        await thunkApi.dispatch(fetchList(challengeUuid));
        return true;
      } else {
        return thunkApi.rejectWithValue(result.error_code);;
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);


const resetEvent = createAsyncThunk(
  'event/reset',
  async (event, thunkApi) => {
    try {      
      const result = await eventRepository.resetEvent(event);
      if (result.success) {
        return true;
      } else {
        return thunkApi.rejectWithValue(result.error_code);
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);


export {
    fetchList,
    refreshScreensOfEvent,
    editEvent,
    createEvent,
    resetEvent,
};