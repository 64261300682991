import { Button, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Header from "../../../common/components/Header";
import Page from "../../../common/components/Page";
import EventList from "../../event/component/EventList";
import { fetchCurrentChallenge } from "../redux/challengeOperations";
import { useEffect } from "react";
import * as eventActions  from "../../event/redux/eventSlice";
import TeamContainer from "../../team/component/TeamContainer";

const ChallengeDetailScreen = (props) => {  
  const { challengeUuid } = useParams();
  const challenge = props.currentChallenge;
  const history = useHistory();

  useEffect(() => {
    props.fetchCurrentChallenge(challengeUuid);
  }, []);

  const backToOverview = () => {
    props.clearCurrentEventList();
    history.replace('/overview');
  }

  return (
    <Page>      
      { challenge &&
        <div>
          <Header title={challenge.title}/>        
          <Paper elevation={0} className="page-detail">
            <Button variant="contained" color="primary" startIcon={<ArrowBack />} onClick={(e) => backToOverview()}>Back to overview</Button>
            <TeamContainer challengeUuid={challengeUuid}/>
            <EventList challengeUuid={challengeUuid}/>
          </Paper>
        </div>
      }
    </Page>
  );
}

const mapStateToProps = (state, ownProps) => ({
  currentChallenge: state.challenge.currentChallenge,
});

const mapDispatchToProps = (dispatch) => ({    
  clearCurrentEventList: () => dispatch(eventActions.clearCurrent()),
  fetchCurrentChallenge: (challengeUuid) => dispatch(fetchCurrentChallenge(challengeUuid)),  
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeDetailScreen);