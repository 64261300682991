import store from '../state/store';
import { logout } from '../features/user/redux/userOperations';

export const getDefaultHeaders = () => {
    return {
        'Content-Type': 'application/json',
    }
}

export const getAuthorizedDefaultHeaders = () => {
    return {
        ...getDefaultHeaders(),
        'Authorization': 'Bearer ' + getTokenFromState()
    };
}

export const authenticatedFetch = async (url, options) => {
    const response = await fetch(url, {
        ...options,
        headers: {
            ...getAuthorizedDefaultHeaders(),
            ...options.headers
        }
    });
    
    if (response.status === 403) {        
        store.dispatch(logout());
        /*const refreshResult = await store.dispatch(refreshAuthenticationToken());
        if (refreshResult.payload) {
            return await fetch(url, {
                ...options,
                headers: {
                    ...options.headers,
                    ...getAuthorizedDefaultHeaders(), //need to get the headers again to update the refresh token
                }
            });
        } else {
            
            throw Error('Can\'t refresh authentication token');
        }*/
    } else {
        return response;
    }
}

function getTokenFromState() {
    return store.getState().user.auth.token;
}


