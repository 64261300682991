import { createAsyncThunk } from "@reduxjs/toolkit";
import { TeamRepository } from "../data/TeamRepository";
import { setInfoForActiveContext } from "./teamSlice";

const teamRepository = new TeamRepository();

export const fetchTeamInfo = createAsyncThunk(
  'team/fetchInfo',
  async (challengeUuid, thunkApi) => {
    try {
      const teamInfo = await teamRepository.fetchInfo(challengeUuid);
      thunkApi.dispatch(setInfoForActiveContext({
        challengeUuid: challengeUuid,
        info: teamInfo,
      }));
      
      return teamInfo;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createTeam = createAsyncThunk(
  'team/create',
  async (newTeam, thunkApi) => {
    try {
      const challengeUuid = thunkApi.getState().team.activeContext.challengeUuid;
      const result = await teamRepository.createTeam(challengeUuid, newTeam);

      if (result.success) {
        await thunkApi.dispatch(fetchTeamInfo(challengeUuid));
        return true;
      } else {
        return thunkApi.rejectWithValue(result.error_code);
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const editTeam = createAsyncThunk(
  'team/edit',
  async (team, thunkApi) => {
    try {
      const challengeUuid = thunkApi.getState().team.activeContext.challengeUuid;
      const result = await teamRepository.editTeam(team);
      if (result.success) {
        await thunkApi.dispatch(fetchTeamInfo(challengeUuid));
        return true;
      } else {
        return thunkApi.rejectWithValue(result.error_code);
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);


export const unassignPlayer = createAsyncThunk(
  'team/unassignPlayer',
  async (player, thunkApi) => {
    try {
      const challengeUuid = thunkApi.getState().team.activeContext.challengeUuid;
      const result = await teamRepository.unassignPlayer(challengeUuid, player.uuid);
      if (result.success) {
        await thunkApi.dispatch(fetchTeamInfo(challengeUuid));
        return true;
      } else {
        return thunkApi.rejectWithValue(result.error_code);
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const assignPlayersToTeam = createAsyncThunk(
  'team/assignPlayersToTeam',
  async (data, thunkApi) => {
    try {
      const challengeUuid = thunkApi.getState().team.activeContext.challengeUuid;
      const result = await teamRepository.assignPlayersToTeam(data.teamUuid, data.playerUuids);
      if (result.success) {
        await thunkApi.dispatch(fetchTeamInfo(challengeUuid));
        return true;
      } else {
        return thunkApi.rejectWithValue(result.error_code);
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);