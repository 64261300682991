import { IconButton, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { RemoveCircleIcon } from "../../../../common/components/icons";

const DeactivatedPlayerList = (props) => {  
  var sortedPlayers = [];
  if (props.players != null) {
    sortedPlayers = props.players.sort((a, b) => a.name.localeCompare(b.name));
  }

  return (
    <Paper elevation={3}  className="player-list-wrapper deactivated-player-list">
      <h3>Deactivated players:</h3>
      <div className="player-list">
        { sortedPlayers.map(player => {
          return <div key={player.uuid} className="player-list-item">
            <div className="player-list-item-contents">
              <span>{player.name}</span>
              <div className="player-controls">
                <IconButton className="delete-button" onClick={(e) => props.onReactivatePlayer(player)}>
                  <RemoveCircleIcon />
                </IconButton>
              </div>
            </div>
          </div>
        })}
      </div>
    </Paper>
  )
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DeactivatedPlayerList);