import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomerRepository } from "../data/CustomerRepository";
import { setList } from "./customerSlice";

const customerRepository = new CustomerRepository();

export const fetchList = createAsyncThunk(
  'customer/fetchList',
  async (arg, thunkApi) => {
    try {
      const customerList = await customerRepository.fetchList();
      thunkApi.dispatch(setList(customerList));
      
      return {success: true};
    } catch (error) {            
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const editCustomer = createAsyncThunk(
  'customer/edit',
  async (customer, thunkApi) => {
    try {
      const result = await customerRepository.editCustomer(customer);
      if (result.success) {
        await thunkApi.dispatch(fetchList());
        return true;
      } else {
        return thunkApi.rejectWithValue(result.error_code);;
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createCustomer = createAsyncThunk(
  'customer/create',
  async (customer, thunkApi) => {
    try {
      const result = await customerRepository.createCustomer(customer);
      if (result.success) {
        await thunkApi.dispatch(fetchList());
        return true;
      } else {
        return thunkApi.rejectWithValue(result.error_code);;
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPlayers = createAsyncThunk(
  'customer/get-players',
  async (customer, thunkApi) => {
    try {
      const result = await customerRepository.fetchPlayers(customer);
      if (!result.error) {                
        return result.players;
      } else {
        return thunkApi.rejectWithValue(result.error);;
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updatePlayers = createAsyncThunk(
    'customer/update-players',
    async (updatePlayerData, thunkApi) => {
      try {
        const result = await customerRepository.updatePlayers(updatePlayerData);
        if (!result.error) {
          return result;
        } else {
          return thunkApi.rejectWithValue(result.error);;
        }
      } catch (error) {
        return thunkApi.rejectWithValue(error);
      }
    }
  );