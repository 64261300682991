import ApplicationConfig from "../../../ApplicationConfig";
import * as apiHelper from "../../../helpers/apiHelpers";

export class ChallengeRepository {

    async fetchList(customerUuid) {
        const response = await apiHelper.authenticatedFetch(
            ApplicationConfig.BACKEND_URL + '/challenge/list?customer_uuid=' + customerUuid,
            {}
        );

        const responseBody = await response.json();        
        return responseBody;
    }

    async fetchChallenge(challengeUuid) {
        const response = await apiHelper.authenticatedFetch(
            ApplicationConfig.BACKEND_URL + '/challenge/item/' + challengeUuid,
            {}
        );

        const responseBody = await response.json();
        
        return responseBody;
    }


    async editChallenge(challenge) {
        const postBody = {
            uuid: challenge.uuid,
            title: challenge.title,
        };

        const response = await apiHelper.authenticatedFetch(
            ApplicationConfig.BACKEND_URL + '/challenge/edit', 
            {
                method: 'POST',
                body: JSON.stringify(postBody),
            }
        );

        const responseBody = await response.json();
        return responseBody;
    }

    async createChallenge(customerUuid, title) {
        const postBody = {
            title: title,
            customerUuid: customerUuid
        };

        const response = await apiHelper.authenticatedFetch(
            ApplicationConfig.BACKEND_URL + '/challenge/create', 
            {
                headers: apiHelper.getAuthorizedDefaultHeaders(),
                method: 'POST',
                body: JSON.stringify(postBody),
            }
        );

        const responseBody = await response.json();
        return responseBody;
    }

    async activateChallenge(customerUuid, challengeUuid) {
        const postBody = {            
            customerUuid: customerUuid,
            challengeUuid: challengeUuid,
        };

        const response = await apiHelper.authenticatedFetch(
            ApplicationConfig.BACKEND_URL + '/challenge/activate', 
            {
                headers: apiHelper.getAuthorizedDefaultHeaders(),
                method: 'POST',
                body: JSON.stringify(postBody),
            }
        );

        const responseBody = await response.json();
        return responseBody;
    }

    async copyChallenge(toCustomerUuid, challengeUuid) {
        const postBody = {            
            toCustomerUuid: toCustomerUuid,
            challengeUuid: challengeUuid,
        };

        const response = await apiHelper.authenticatedFetch(
            ApplicationConfig.BACKEND_URL + '/challenge/copy', 
            {
                headers: apiHelper.getAuthorizedDefaultHeaders(),
                method: 'POST',
                body: JSON.stringify(postBody),
            }
        );

        const responseBody = await response.json();
        return responseBody;
    }
}