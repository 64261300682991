import { Button, IconButton, Paper, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import EditIcon from "@material-ui/icons/Edit";
import WarningIcon from "@material-ui/icons/WarningRounded";
import { useEffect, useState } from "react";
import { createEvent, editEvent, refreshScreensOfEvent, resetEvent } from "../redux/eventOperations";
import ScreenListItem from "../../screen/component/ScreenListItem";
import { ScreenRepository } from "../../screen/data/ScreenRepository";
import LanguagePicker from "../../../common/components/LanguagePicker";
import EventDurationControl from "./EventDurationControl";

const EventListItem = (props) => {
  const event = props.event;
  const [isEditing, setIsEditing] = useState(event === null);
  const [title, setTitle] = useState('');
  const [isOpened, setIsOpened] = useState(false);
  const [isCreatingScreen, setIsCreatingScreen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(props.languages[0]);

  useEffect(() => {
    if (event) {
      setTitle(event.title ? (event.title[currentLanguage] ? event.title[currentLanguage] : '' ) : '');
    } else {
      setTitle('');
    }
    
  }, [currentLanguage]);

  const saveTitle = () => {
    setIsEditing(false);
    if (event != null) {
      var eventToEdit = { ...event, title: { ...event.title}};
      eventToEdit.title[currentLanguage] = title;

      props.editEvent(eventToEdit);
    } else {
      var newEvent = {
        title: {},
        weight: 0,
      }

      newEvent.title[currentLanguage] = title;

      props.createEvent(newEvent);
      props.onCreated();
    }
  }

  const saveDuration = (duration) => {
    let eventToEdit = {
      ...event,
      duration: duration,
    };

    props.editEvent(eventToEdit);
  }

  const onCreateNewScreen = (title, type) => {
    const screenRepository = new ScreenRepository();
    screenRepository.createScreen(event.uuid, {
      title: title,
      type: type,
      data: {components: []} 
    }).then((result) => {
      setIsCreatingScreen(false);
      props.refreshScreensOfEvent(event);
    })
  }

  const handleResetClicked = () => {
    const input = prompt('Are you sure? This will erase ALL event player data');
    if (input === 'yes') {
      props.resetEvent(event);
    }
  }
                  
  return (
    <Paper elevation={2} className="event-list-item">
      <div className="event-list-item-header">
        { isEditing 
          ? <TextField placeholder={'Event title (' + currentLanguage + ')'} value={title} onChange={(e) => setTitle(e.target.value)} onKeyPress={(e) => {if(e.key === 'Enter') saveTitle()}} autoFocus/>
          : <div>
              <strong className="text-content">Event: {title}</strong> 
              <IconButton onClick={(e) => setIsEditing(true)}><EditIcon/></IconButton>
            </div>
        }
        {
          props.event &&
          <div>            
            <LanguagePicker currentLanguage={currentLanguage} languages={props.languages} onChange={(language) => setCurrentLanguage(language)}/>          
            <IconButton color="primary" onClick={(e) => setIsOpened(!isOpened)}>{isOpened ? <RemoveIcon/> : <AddIcon/>}</IconButton>
          </div>
        }
      </div>
    
      {isOpened && 
        <div>
          <div className="event-list-item-screens">
            {event.screens.map((screen) => {
              return <ScreenListItem key={screen.uuid} screen={screen} language={currentLanguage}/>
            })}
            { isCreatingScreen
              && <ScreenListItem key="new" onCreateScreen={onCreateNewScreen}/>
            }
          </div>
          <div className="event-list-item-controls">
            <EventDurationControl duration={event.duration} onUpdate={saveDuration}/>
            <div className="event-list-item-controls-right">
              <Button variant="contained" color="primary" startIcon={<WarningIcon/>} endIcon={<WarningIcon/>} onClick={(e) => handleResetClicked()}>Reset</Button>
              <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={(e) => setIsCreatingScreen(true)}>New screen</Button>
            </div>
          </div>
        </div>
      } 
    </Paper>
  )
}

const mapStateToProps = (state) => ({
  languages: state.challenge.currentChallenge.customer.availableLanguages,
});

const mapDispatchToProps = (dispatch) => ({
  editEvent: (event) => dispatch(editEvent(event)),
  createEvent: (event) => dispatch(createEvent(event)),
  refreshScreensOfEvent: (event) => dispatch(refreshScreensOfEvent(event)),
  resetEvent: (event) => dispatch(resetEvent(event)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventListItem);