
export const mapUserInfo = (responseBody) => {
    return {
        firstName: responseBody.firstName,
        lastName: responseBody.lastName,
        companyName: responseBody.company,
    }
}

export const mapAuthenticationResponse = (responseBody) => {
    return {
        token: responseBody.token,
        refreshToken: responseBody.refresh_token,
    }
}