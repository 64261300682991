import { Button, Dialog, DialogActions, DialogContent, Slide } from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { connect } from "react-redux";
import { createCustomer } from "../redux/customerOperations";
import CustomerForm from "./CustomerForm";

//TODO: loading
const CustomerCreateDialog = (props) => {  
  const [isOpen, setIsOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [customerData, setCustomerData] = useState(null);

  const onConfirm = () => {
    const createCustomerData = {
      ...customerData,
    };

    props.createCustomer(createCustomerData).then(unwrapResult).then((result) => {
      setIsOpen(false);
    }).catch(errorCode => {
      setErrorMessage('Something went wrong, please try again later. ' + (errorCode != null ? '(' + errorCode + ')' : ''));             
    });
  }

  return (
    <Dialog open={isOpen} TransitionComponent={Transition} onExited={(e) => {props.onClose()}}>
      <DialogContent>
        <div className="dialog-content">
          <h2>{'Create new customer'}</h2><br/>
          <CustomerForm onDataEdited={(data) => setCustomerData(data)}/>
          {errorMessage != null && <p className="error dialog-error">{errorMessage}</p>}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => setIsOpen(false)}>
          Cancel
        </Button>
        <Button onClick={(e) => onConfirm()}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  createCustomer: (customer) => dispatch(createCustomer(customer)),
});
 
export default connect(mapStateToProps, mapDispatchToProps)(CustomerCreateDialog);


