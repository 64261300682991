import { IconButton, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NextIcon, PreviousIcon } from "../../../common/components/icons";

const MediaList = (props) => {  
  const mediaItems = props.items;
  const [listOffset, setListOffset] = useState(0);
  const [searchText, setSearchText] = useState('');  
  const [selectedMedia, setSelectedMedia] = useState(null);
  
  useEffect(() => {
    setSelectedMedia(null);
    props.onRequestStateUpdated({
      offset: listOffset,
      limit: props.itemsPerPage,
      searchText: searchText === '' ? null : searchText,
    });
  }, [listOffset, searchText]);

  useEffect(() => {
    props.onMediaSelected(selectedMedia);
  }, [selectedMedia]);

  const onPreviousClicked = () => {
    setListOffset(Math.max(listOffset - props.itemsPerPage, 0));    
  }

  const onNextClicked = () => {
    setListOffset(listOffset + props.itemsPerPage);
  }

  return (
    <div className="media-list-wrapper">
      <div className="media-list">
        { mediaItems != null && mediaItems.map(media => {
          var className = 'media-item';
          const style = {'backgroundImage': 'url("' + media.url + '")'};
          if (selectedMedia != null && selectedMedia.key === media.key) className += ' selected';

          return <div className={className} key={media.key} onClick={(e) => setSelectedMedia(media)}>
            <div className="media-item-content" style={style}/>
          </div>
          }
        )}
      </div>
      {
        mediaItems === null && <span>Loading...</span>
      }
      <div className="media-list-controls">
        <IconButton color="primary" onClick={()=>{onPreviousClicked()}} disabled={listOffset === 0}><PreviousIcon/></IconButton>
        <IconButton color="primary" onClick={()=>{onNextClicked()}} disabled={mediaItems && mediaItems.length < props.itemsPerPage}><NextIcon/></IconButton>
        <TextField placeholder={'Search in tags'} value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
      </div>

    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  
});

const mapDispatchToProps = (dispatch) => ({  
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaList);