
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";

const ScreenComponent = (props) => {
  const component = props.component;
  
  return (
    <div className={'screen-component ' + props.className}>
      <div className="screen-component-header">
        <div className="screen-component-title">
          <p><strong>Component:</strong> {props.type}</p>
          <IconButton onClick={(e) => props.onDelete(component)}><DeleteIcon /></IconButton>
        </div>        
      </div>
      <div className="screen-component-details">
        {props.children}
      </div>
    </div>
  )
}


const mapStateToProps = (state, ownProps) => ({  
  
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ScreenComponent);