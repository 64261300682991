import { configureStore } from "@reduxjs/toolkit";
import customerReducer from "../features/customer/redux/customerSlice";
import challengeReducer from "../features/challenge/redux/challengeSlice";
import userReducer from "../features/user/redux/userSlice";
import eventReducer from "../features/event/redux/eventSlice";
import teamReducer from "../features/team/redux/teamSlice";

export default configureStore({
    reducer: {
        user: userReducer,
        customer: customerReducer,
        challenge: challengeReducer,
        event: eventReducer,
        team: teamReducer,
    },
})