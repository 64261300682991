import { createAsyncThunk } from "@reduxjs/toolkit";
import { MediaRepository } from "../data/MediaRepository";

const mediaRepository = new MediaRepository();

export const fetchList = createAsyncThunk(
  'media/list',
  async (request, thunkApi) => {
    try {
      const result = await mediaRepository.fetchList(request.searchText, request.limit, request.offset);
            
      return result.mediaItems;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const uploadImage = createAsyncThunk(
  'media/upload-image',
  async (data, thunkApi) => {
    try {      
      const result = await mediaRepository.uploadImage(data.base64Data, data.tags);

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const editMedia = createAsyncThunk(
  'media/edit',
  async (data, thunkApi) => {
    try {      
      const result = await mediaRepository.editMedia(data.key, data.tags);

      return result;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
