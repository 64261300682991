import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserRepository } from "../data/UserRepository";
import { setAuthenticationInfo, setIsLoggedIn } from "./userSlice";
import * as persistenceHelper from "../../../helpers/persistenceHelpers";
import { PersistenceKeys } from "../../../helpers/constants";

const userRepository = new UserRepository();

//TODO: what's the plan here? 
const AuthenticationError = {
    INVALID_CREDENTIALS: 100,
    REFRESH_FAILED: 101,
    UNKNOWN_ERROR: 199,
}

const login = createAsyncThunk(
    'user/login',
    async (credentials, thunkApi) => {
        try {
            const authenticationInfo = await userRepository.login(credentials.userName, credentials.password);
            thunkApi.dispatch(setAuthenticationInfo(authenticationInfo));
            storeAuthenticationInfo(authenticationInfo);
            
            //const userInfo = await userRepository.fetchUserInfo();
            //thunkApi.dispatch(setUserInfo(mapUserInfo(userInfo)));

            //TODO: Maybe move this to the user info component? 
            thunkApi.dispatch(setIsLoggedIn(true));
            
            return true;            
        } catch (error) {            
            return thunkApi.rejectWithValue(error);
        }
    }
)

const refreshAuthenticationToken = createAsyncThunk(
    'user/refreshAuth',
    async (arg, thunkApi) => {
        const refreshToken = thunkApi.getState().user.auth.refreshToken;
        try {
            const refreshResponse = await userRepository.refreshAuthenticationToken(refreshToken);            
            storeAuthenticationInfo(refreshResponse);
            thunkApi.dispatch(setAuthenticationInfo(refreshResponse));

            return true;
        } catch(e) {
            return false;
        }        
    }    
)

const logout = createAsyncThunk(
    'user/logout',
    async (arg, thunkApi) => {
        thunkApi.dispatch(setAuthenticationInfo({token: null, refreshToken: null}));
        thunkApi.dispatch(setIsLoggedIn(false));
        persistenceHelper.deleteString(PersistenceKeys.AUTHENTICATION_TOKEN);
        persistenceHelper.deleteString(PersistenceKeys.AUTHENTICATION_REFRESH_TOKEN);

        return true;
    }    
)

const bootstrapAuthentication = createAsyncThunk(
    'user/bootstrapAuth',
    async (arg, thunkApi) => {
        const authToken = persistenceHelper.getString(PersistenceKeys.AUTHENTICATION_TOKEN);
        const refreshToken = persistenceHelper.getString(PersistenceKeys.AUTHENTICATION_REFRESH_TOKEN);
        
        if (authToken && refreshToken) {
            thunkApi.dispatch(setAuthenticationInfo({
                token: authToken,
                refreshToken: refreshToken
            }));
            thunkApi.dispatch(setIsLoggedIn(true));
    
            //TODO: Maybe move this to the user info component? 
            /*const userInfo = await userRepository.fetchUserInfo();
            thunkApi.dispatch(setUserInfo(mapUserInfo(userInfo)));*/
        }        

        return true;
    }
)

const demo = createAsyncThunk(
    'user/demo', 
    async (arg, thunkApi) => {
        const body = await userRepository.demoCall();
        console.log(body);

        return true;
    }
)

const storeAuthenticationInfo = (authenticationInfo) => {
    persistenceHelper.persistString(PersistenceKeys.AUTHENTICATION_TOKEN, authenticationInfo.token);
    persistenceHelper.persistString(PersistenceKeys.AUTHENTICATION_REFRESH_TOKEN, authenticationInfo.refreshToken);
}

export {
    AuthenticationError,
    login,
    bootstrapAuthentication,
    refreshAuthenticationToken,
    logout,
    demo,
};