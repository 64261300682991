import { Button, CircularProgress, TextField } from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";
import { connect } from "react-redux";
import { AuthenticationError, login } from "../redux/userOperations";

const LoginForm = (props) => {   
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleLoginPressed = () => {
        setErrorMessage(null);
        setIsLoading(true);
        props.login(userName, password).then(unwrapResult).then(result => {
            
        }).catch(error => {
            setIsLoading(false);
            console.log(error);
            if (error === AuthenticationError.INVALID_CREDENTIALS) {                
                setErrorMessage('Incorrect credentials'); //TODO: translation
            } else {
                setErrorMessage('Something unexpected went wrong, please try again later.'); //TODO: translation
            }            
        });
    };

    //TODO: translation
    return (
        <div className="login-form">
            <TextField onChange={(e) => setUserName(e.target.value)} label="User name" variant="filled" value={userName}/><br/>
            <TextField onChange={(e) => setPassword(e.target.value)} label="Password" type="password" variant="filled" value={password}/><br/><br/>
            {
                isLoading 
                    ? <CircularProgress />
                    : <Button variant="contained" color="primary" onClick={(e) => handleLoginPressed()}>Login</Button>
            }
            {
                errorMessage != null && <p className="error">{errorMessage}</p>
            }
            
        </div>
    )
}


const mapStateToProps = (state, ownProps) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    login: (userName, password) => dispatch(login({userName: userName, password: password})),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);