import ApplicationConfig from "../../../ApplicationConfig";
import * as apiHelper from "../../../helpers/apiHelpers";
import { AuthenticationError } from "../redux/userOperations";
import { mapAuthenticationResponse } from "./userMappers";

export class UserRepository {

    async login(userName, password) {
        const postBody = {
            username: userName,
            password: password,
        }

        const response = await fetch(ApplicationConfig.BACKEND_URL + '/auth/login', {
            headers: apiHelper.getDefaultHeaders(),
            method: 'POST',
            body: JSON.stringify(postBody),
        });
        
        if (response.status === 200) {            
            const responseBody = await response.json();
            return mapAuthenticationResponse(responseBody);
        } else if (response.status === 401) {
            throw AuthenticationError.INVALID_CREDENTIALS;
        } else {
            throw Error('unexpected response');
        }
    }

    async refreshAuthenticationToken(refreshToken) {
        const postBody = {
            refresh_token: refreshToken,
        }

        const response = await fetch(ApplicationConfig.BACKEND_URL + '/api/token/refresh', {
            headers: apiHelper.getDefaultHeaders(),
            method: 'POST',
            body: JSON.stringify(postBody),
        });
                
        if (response.status === 200) {
            const responseBody = await response.json();
            return mapAuthenticationResponse(responseBody);
        } else {
            throw AuthenticationError.REFRESH_FAILED;
        }
        
    }

    async fetchUserInfo() {
        const response = await apiHelper.authenticatedFetch(ApplicationConfig.BACKEND_URL + '/api/user/info', {
            headers: apiHelper.getAuthorizedDefaultHeaders(),
            method: 'GET',
        });

        const responseBody = await response.json(); 
        return responseBody;
    }

    async demoCall() {
        const response = await fetch(ApplicationConfig.BACKEND_URL + '/api/demo', {
            headers: apiHelper.getAuthorizedDefaultHeaders(),
            method: 'GET',
        });

        const responseBody = await response.json(); 
        return responseBody;
    }
}