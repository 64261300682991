import { Button, Dialog, DialogActions, DialogContent, Slide } from "@material-ui/core";
import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getPlayers, updatePlayers } from "../../redux/customerOperations";
import DeactivatedPlayerList from "./DeactivatedPlayerList";
import NewPlayerList from "./NewPlayerList";
import PlayerList from "./PlayerList";

//TODO: loading
const CustomerPlayerDialog = (props) => {  
  const [isOpen, setIsOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [activePlayers, setActivePlayers] = useState(null);
  const [newPlayers, setNewPlayers] = useState([]);
  const [deactivatedPlayers, setDeactivatedPlayers] = useState([]);
  const [updatedPlayers, setUpdatedPlayers] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const players = await props.getPlayers(props.customer).then(unwrapResult);
        setActivePlayers(players);
      } catch(e) {
        setErrorMessage('Something went wrong fetching the players: ' + e.toString());
      }
    }

    fetchData();
  }, []);

  const onDeactivatePlayer = (player) => {
    setActivePlayers(activePlayers.filter((activePlayer) => activePlayer.uuid !== player.uuid));
    setUpdatedPlayers(updatedPlayers.filter((updatedPlayer) => updatedPlayer.uuid !== player.uuid));
    setDeactivatedPlayers([...deactivatedPlayers, player]);
  }

  const onUpdatePlayer = (playerToUpdate) => {
    setActivePlayers(activePlayers.map((activePlayer) => {
      if (activePlayer.uuid === playerToUpdate.uuid) {
        return playerToUpdate;
      } 
      return activePlayer;
    }));

    setUpdatedPlayers([...updatedPlayers, playerToUpdate]);
  }

  const onReactivatePlayer = (player) => {
    setDeactivatedPlayers(deactivatedPlayers.filter((deactivedPlayer) => deactivedPlayer.uuid !== player.uuid));
    setActivePlayers([...activePlayers, player]);
  }

  const onConfirm = () => {
    const playerUpdateData = {
      customerUuid: props.customer.uuid,
      deactivatedPlayers: deactivatedPlayers,
      updatedPlayers: updatedPlayers,
      newPlayers: newPlayers,
    };

    props.updatePlayers(playerUpdateData).then(unwrapResult).then((result) => {
      setIsOpen(false);
    }).catch(errorCode => {
      setErrorMessage('Something went wrong, please try again later. ' + (errorCode != null ? '(' + errorCode + ')' : ''));             
    });
  }

  return (
    <Dialog open={isOpen} TransitionComponent={Transition} onExited={(e) => {props.onClose()}} maxWidth={'md'} fullWidth={true}>
      <DialogContent>
        <div className="player-dialog dialog-content">
          {
            activePlayers != null 
            ? <div>
                <h2>{'Manage players for \'' + props.customer.title + '\''}</h2><br/>
                <div className="player-lists">
                  <PlayerList 
                    players={ activePlayers } 
                    onDeactivatePlayer={ onDeactivatePlayer }
                    onUpdatePlayer = { onUpdatePlayer }
                  />
                  <DeactivatedPlayerList
                    players={ deactivatedPlayers }
                    onReactivatePlayer={ onReactivatePlayer }
                  />
                  <NewPlayerList                    
                    onNewPlayersUpdated={ (newPlayers) => setNewPlayers(newPlayers) }
                  />
                </div>
                {errorMessage != null && <p className="error dialog-error">{errorMessage}</p>}
              </div>
            : <p>Loading...</p>
          }          
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => setIsOpen(false)}>
          Cancel
        </Button>
        <Button onClick={(e) => onConfirm()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getPlayers: (customer) => dispatch(getPlayers(customer)),
  updatePlayers: (updatePlayerData) => dispatch(updatePlayers(updatePlayerData)),
});
 
export default connect(mapStateToProps, mapDispatchToProps)(CustomerPlayerDialog);

