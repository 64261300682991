import { Button, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchList } from "../redux/customerOperations";
import AddIcon from "@material-ui/icons/Add";
import CustomerCreateDialog from "./CustomerCreateDialog";
import CustomerEditDialog from "./CustomerEditDialog";
import CustomerListItem from "./CustomerListItem";
import CustomerPlayerDialog from "./player_dialog/CustomerPlayerDialog";

const CustomerList = (props) => {
  const [customerToEdit, setcustomerToEdit] = useState(null);
  const [isCreatingCustomer, setIsCreatingCustomer] = useState(false);
  const [openedCustomerUuid, setOpenedCustomerUuid] = useState(null);
  const [playerDialogCustomer, setPlayerDialogCustomer] = useState(null);
  
  useEffect(() => {
    props.fetchList();
  }, []);

  const onEditCustomer = (customer) => {
    setcustomerToEdit(customer);
  };

  const onCreateCustomerPressed = () => {
    setIsCreatingCustomer(true);
  };

  return (
    <div>
      <Paper elevation={0} className="customer-list">
          <h1>Customers:</h1>
          {props.customers != null && props.customers.map(customer => {
            return <CustomerListItem 
              customer={ customer } 
              key={ customer.uuid } onEdit={onEditCustomer}
              onOpen={ () => setOpenedCustomerUuid(customer.uuid) }
              onClose={ () => setOpenedCustomerUuid(null) }
              open={ customer.uuid === openedCustomerUuid }
              onOpenPlayerDialog={() => setPlayerDialogCustomer(customer)}
            />;
          })}
          <div className="customer-list-controls">
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={(e) => onCreateCustomerPressed()}>Create customer</Button>
          </div>
      </Paper>

      { customerToEdit != null && <CustomerEditDialog customer={customerToEdit} onClose={() => setcustomerToEdit(null)}/> }
      { isCreatingCustomer && <CustomerCreateDialog onClose={() => setIsCreatingCustomer(false)}/> }
      { playerDialogCustomer && <CustomerPlayerDialog customer={playerDialogCustomer} onClose={() => setPlayerDialogCustomer(null)}/> }
    </div>
  )
}

const mapStateToProps = (state) => ({
  customers: state.customer.list,
});

const mapDispatchToProps = (dispatch) => ({
  fetchList: () => dispatch(fetchList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);