import { Button, IconButton, Paper, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import EditIcon from "@material-ui/icons/Edit";
import { useState } from "react";
import ScreenRichTextComponent from "./ScreenRichTextComponent";
import ScreenTitleComponent from "./ScreenTitleComponent";
import { generateShortKey } from "../../../util/uniqueIdUtil";
import { ScreenRepository } from "../data/ScreenRepository";
import ScreenImageComponent from "./ScreenImageComponent";
import ScreenQuestionComponent from "./ScreenQuestionComponent";
import ScreenMultipleChoiceComponent from "./ScreenMultipleChoiceComponent";

const ScreenListItem = (props) => {
  const screen = props.screen;
  const [isOpened, setIsOpened] = useState(false);
  const [components, setComponents] = useState(screen ? screen.data.components : []);
  const [isEditingTitle, setIsEditingTitle] = useState(screen ? false : true);
  const [title, setTitle] = useState(screen ? screen.title : '');
  
  const onConfirmTitle = () => {
    setIsEditingTitle(false);
    if (screen) {
      updateScreen(title, components);
    } else {
      props.onCreateScreen(title, 'content');
    }
  }

  const onAddComponent = (componentType) => {
    const newComponent = {
      key: generateShortKey(),
      type: componentType,
    }
    
    setComponents([...components, newComponent]);
  }

  //TODO: move this to screen operations
  //TODO: show notification on save
  const onSaveComponent = (componentToUpdate) => {
    var updatedComponents = components.map((component) => {
      if (component.key === componentToUpdate.key) {
        return componentToUpdate;
      } else {
        return component;
      }
    });

    updateScreen(title, updatedComponents);
  }

  //TODO: move this to screen operations
  const onDeleteComponent = (componentToDelete) => {
    const updatedComponents = components.filter((component) => {
      return component.key !== componentToDelete.key;
    });

    updateScreen(title, updatedComponents);
  }

  //TODO: move this to screen operations
  const updateScreen = (title, updatedComponents) => {
    const screenRepository = new ScreenRepository();
    screenRepository.editScreen({
      ...screen,
      title: title,
      data: {
        components: updatedComponents,        
      },      
    }).then((result) => {
      setTitle(title);
      setComponents(updatedComponents);
    });
  }

  return (
    <Paper elevation={1} className="screen-list-item">
      <div className="screen-list-item-header">
        <div className="screen-list-item-title">
          <p>Screen: </p>
          { isEditingTitle
            ? <TextField variant="outlined" label="Text" value={title} onChange={(e) => setTitle(e.target.value)} onKeyPress={(e) => {if(e.key === 'Enter') onConfirmTitle()}} autoFocus/>
            : <div><p>{title}</p><IconButton onClick={(e) => setIsEditingTitle(true)}><EditIcon /></IconButton></div>
          }
        </div>
        <div>
          { props.screen && <IconButton color="primary" onClick={(e) => setIsOpened(!isOpened)}>{isOpened ? <RemoveIcon/> : <AddIcon/>}</IconButton>}
        </div>
      </div>
      { isOpened && 
        <div className="screen-list-item-details">
          {components.map((component) => {
            return renderScreenComponent(component, onSaveComponent, onDeleteComponent, props.language);
          })}
          {getComponentControlsForScreen(screen, onAddComponent)}
        </div>
      }
    </Paper>
  )
}

const renderScreenComponent = (screenComponent, onSaveComponent, onDeleteComponent, language) => {
  const props = {
    key: screenComponent.key,
    component: screenComponent,
    onSave: onSaveComponent,
    onDelete: onDeleteComponent,
    language: language,
  };

  if (screenComponent.type === 'title') {
    return (<ScreenTitleComponent {...props}/>);
  } else if (screenComponent.type === 'rich_text') {
    return (<ScreenRichTextComponent {...props} />);
  } else if (screenComponent.type === 'image') {
    return (<ScreenImageComponent {...props} />);
  } else if (screenComponent.type === 'question') {
    return (<ScreenQuestionComponent {...props} />);
  } else if (screenComponent.type === 'multiple_choice') {
    return (<ScreenMultipleChoiceComponent {...props} />);
  } else {
    return (<p>Unknown component</p>);
  }
};

const getComponentControlsForScreen = (screen, onAddComponent) => {
  return (
    <div className="add-component-controls">
      <Button variant="contained" color="primary" startIcon={<AddIcon/>} onClick={(e) => onAddComponent('title')}>Title</Button>
      <Button variant="contained" color="primary" startIcon={<AddIcon/>} onClick={(e) => onAddComponent('rich_text')}>Rich text</Button>
      <Button variant="contained" color="primary" startIcon={<AddIcon/>} onClick={(e) => onAddComponent('image')}>Image</Button>
      <Button variant="contained" color="primary" startIcon={<AddIcon/>} onClick={(e) => onAddComponent('question')}>Question</Button>
      <Button variant="contained" color="primary" startIcon={<AddIcon/>} onClick={(e) => onAddComponent('multiple_choice')}>Multiple choice</Button>
    </div>
  )
};

const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ScreenListItem);