import { Grid } from "@material-ui/core";

const Page = (props) => {
    return (
        <div className="page">
            <Grid container spacing={1}>
                <Grid item xs={3}><br/></Grid>
                <Grid item xs={6}>                    
                    {props.children}                    
                </Grid>
                <Grid item xs={3}><br/></Grid>
            </Grid>
        </div>
      );
}


export default Page;