import { IconButton, Paper, TextField } from "@material-ui/core";
import { useState } from "react";
import { connect } from "react-redux";
import { EditIcon, RemoveCircleIcon } from "../../../../common/components/icons";

const PlayerList = (props) => {
  const [editingPlayerUuid, setEditingPlayerUuid] = useState(null);
  const [editingPlayerName, setEditingPlayerName] = useState(null);

  var sortedPlayers = [];
  if (props.players != null) {
    sortedPlayers = props.players.sort((a, b) => a.name.localeCompare(b.name));
  }

  const startEditingPlayerName = (player) => {
    setEditingPlayerName(player.name);
    setEditingPlayerUuid(player.uuid);
  }

  const onConfirmPlayerNameEdit = () => {
    var playerToUpdate = props.players.find((player) => player.uuid === editingPlayerUuid);
    props.onUpdatePlayer({
      ...playerToUpdate,
      name: editingPlayerName,
      originalName: playerToUpdate.name,
    });

    setEditingPlayerName(null); 
    setEditingPlayerUuid(null);
  }

  return (    
    <Paper elevation={3} className="player-list-wrapper active-player-list">
      <h3>Players:</h3>
      <div className="player-list">
        { sortedPlayers.map(player => {
          return <div key={player.uuid} className="player-list-item">
            { editingPlayerUuid === player.uuid 
              ? <TextField 
                  className="form-textfield" 
                  variant="standard" 
                  onChange={(e) => setEditingPlayerName(e.target.value)}                   
                  value={editingPlayerName} 
                  onKeyPress={(e) => {if(e.key === 'Enter') onConfirmPlayerNameEdit()}}
                  autoFocus
                />
              : <div className="player-list-item-contents">
                  <span>{player.name}</span>
                  <div className="player-controls">
                    <IconButton className="delete-button" onClick={(e) => props.onDeactivatePlayer(player)}>
                      <RemoveCircleIcon />
                    </IconButton>
                    <IconButton className="edit-button" onClick={(e) => startEditingPlayerName(player)}>
                      <EditIcon />
                    </IconButton>
                  </div>
                </div>
            }
          </div>
        })}
      </div>
    </Paper>    
  )
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerList);