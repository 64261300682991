import './assets/app.scss';
import { Redirect, Route, Switch, } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';

import { metisUnitedTheme } from './helpers/themeHelpers';
import LoginScreen from './features/user/component/LoginScreen';
import ChallengeDetailScreen from './features/challenge/component/ChallengeDetailScreen';
import CustomerOverviewScreen from './features/customer/component/CustomerOverviewScreen';

const App = (props) => {
  document.title = 'Metis United Dashboard';

  return (
    <ThemeProvider theme={metisUnitedTheme}>
      <div className="app">        
        {props.isLoggedIn 
        ? 
          <div>
            <Switch>
              <Route exact path="/overview" component={CustomerOverviewScreen}/>
              <Route exact path="/challenge/:challengeUuid" component={ChallengeDetailScreen}/>
              {<Redirect to="/overview"/>}
            </Switch>
          </div>
        : <div>
            <Switch>
              <Route exact path="/login" component={LoginScreen}/>
              {<Redirect to="/login"/>}
            </Switch>
          </div>
        }
      </div>
    </ThemeProvider>
  );
}

const mapStateToProps = (state, ownProps) => ({
  isLoggedIn: state.user.auth.loggedIn, //TODO: maybe make an actual selector from this? 
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
