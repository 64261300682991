import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import ApplicationConfig from "../../ApplicationConfig";
import HeaderMenu from "./HeaderMenu";

const Header = (props) => {

  return (
    <Paper elevation={0}>
    <div className="header">          
      <div className="page-title-wrapper">
        <div className="logo"></div>
        <div className="page-title">
          <h1 className="text-content">{props.title}</h1>
          <div className="environment-indicator">{ApplicationConfig.ENVIRONMENT}</div>
        </div>
      </div>
      <div className="menu">
        <p>
          {'Hello ' + props.user.firstName}
        </p>
        <HeaderMenu />              
      </div>
    </div>
    </Paper>
    );
}

const mapStateToProps = (state, ownProps) => ({
  user: {firstName: 'team!'},
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Header);