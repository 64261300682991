import { Button, Dialog, DialogActions, DialogContent, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import LanguagePicker from "../../../common/components/LanguagePicker";
import Transition from "../../../common/components/Transition";
import MultipleChoiceAnswerList from "./MultipleChoiceAnswerList";

const MultipleChoiceDialog = (props) => {    
  const component = props.component;
  const [isOpen, setIsOpen] = useState(true);
  const [language, setLanguage] = useState(props.languages[0])
  const [points, setPoints] = useState(component.points);
  const [incorrectDeductionPoints, setIncorrectDeductionPoints] = useState(component.incorrectDeductionPoints ? component.incorrectDeductionPoints : 0);  
  const [answers, setAnswers] = useState(component.answers);

  const onConfirm = () => {
    const updatedComponent = {
      ...props.component,
      points: parseInt(points),
      incorrectDeductionPoints: parseInt(incorrectDeductionPoints),
      answers: answers,
    }

    props.onSave(updatedComponent);
    setIsOpen(false);
  }
    
  return (
    <Dialog open={isOpen} TransitionComponent={Transition} maxWidth="lg" onExited={(e) => {props.onClose()}}>
      <DialogContent>
        <div className="question-dialog dialog-content multiple-choice-dialog">
          <div className="settings-header">
            <h2>Multiple choice settings</h2>
            <LanguagePicker currentLanguage={language} languages={props.languages} onChange={setLanguage}/>
          </div>
          <h3>Basic settings:</h3>
          <div className="basic-settings">            
            <div className="points">
              <TextField variant="filled" label="Points" value={points} type="number" onChange={(e) => setPoints(e.target.value)} size="small"/>
            </div>
            <div className="incorrect-deduction-points">
              <TextField variant="filled" label="Points for wrong answer" value={incorrectDeductionPoints} type="number" onChange={(e) => setIncorrectDeductionPoints(e.target.value)} size="small"/>
            </div>
          </div>
          <MultipleChoiceAnswerList languages={props.languages} language={language} answers={answers} onUpdate={(answers) => setAnswers(answers)}/>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => setIsOpen(false)}>
          Cancel
        </Button>
        <Button onClick={(e) => onConfirm()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({

});
 
export default connect(mapStateToProps, mapDispatchToProps)(MultipleChoiceDialog);


