
import { Button, Checkbox, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { generateShortKey } from "../../../util/uniqueIdUtil";

const MultipleChoiceAnswerList = (props) => {

  const onAddAnswer = () => {
    props.onUpdate([
      ...props.answers,
      {
        key: generateShortKey(),
        correct: false,
        title: props.languages.reduce((map, language) => {          
          map[language] = 'Answer text (' + language + ')';
          return map;
        }, {}),        
      }
    ]);
  }

  const updateAnswerLanguageDependentProperty = (answerToUpdate, property, value) => {
    var languageDependentProperty = {...answerToUpdate[property]};
    languageDependentProperty[props.language] = value;

    updateAnswerProperty(answerToUpdate, property, languageDependentProperty);
  }

  const updateAnswerProperty = (answerToUpdate, property, value) => {
    const updatedAnswers = props.answers.map((answer) => {
      if (answer.key === answerToUpdate.key) {
        var updatedAnswer = {...answerToUpdate};
        updatedAnswer[property] = value;

        return updatedAnswer;
      } else {
        return answer;
      }
    });

    props.onUpdate(updatedAnswers);
  }

  return (
    <div className="answer-list">
      <h3>Answers:</h3>
      <div className="answer-list-item-wrapper">
        {
          props.answers.map((answer) => {
            return (
              <div key={answer.key} className="answer-list-item">
                <TextField 
                  className="answer-textfield"
                  variant="filled" 
                  label="Title" 
                  value={answer.title[props.language]} 
                  onChange={(e) => updateAnswerLanguageDependentProperty(answer, 'title', e.target.value)} 
                  size="small"
                />
                <span> Correct:</span>
                <Checkbox 
                  checked={answer.correct} 
                  onChange={(e) => updateAnswerProperty(answer, 'correct', e.target.checked)}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            );
          })
        }
      </div>
      <Button variant="contained" color="primary" onClick={(e) => onAddAnswer()}>Add answer</Button>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MultipleChoiceAnswerList);

