
import { Button, IconButton } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import EditIcon from "@material-ui/icons/Edit";
import ScreenComponent from "./ScreenComponent";

const ScreenRichTextComponent = (props) => {    
  const component = props.component;
  const language = props.language;
  const [richText, setRichText] = useState('');
  const [isEditing, setIsEditing] = useState(component.richText ? false : true);

  useEffect(() => {
    setRichText(component.richText ? (component.richText[language] ? component.richText[language] : '' ) : '');
  }, [props.language]);

  const onConfirm = () => {
    var updatedRichText = {
      ...(component.richText ? component.richText : {})
    };
    updatedRichText[language] = richText;

    console.log('Text from editor:', richText);

    props.onSave({
      ...component,
      richText: updatedRichText,
    });
    setIsEditing(false);
  }

  return (
      <ScreenComponent className="screen-rich-text-component" component={props.component} onDelete={props.onDelete} type="Rich text">        
         <div className="screen-rich-text-component-content">
            <p>Content:</p> 
            { isEditing 
              ? <div className="rich-text-editor">
                  <ReactQuill
                    theme="snow"
                    value={richText}
                    onChange={setRichText}
                    modules={{clipboard: { matchVisual: false }}}
                  />
                </div>
              : <div className="rich-text" dangerouslySetInnerHTML={{__html:richText}}></div>
            }
            { isEditing
              ? <Button variant="contained" onClick={onConfirm} color="primary">Save</Button>
              : <IconButton onClick={(e) => setIsEditing(true)}><EditIcon /></IconButton>
            }            
          </div>      
      </ScreenComponent>
  );
}

const mapStateToProps = (state, ownProps) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ScreenRichTextComponent);