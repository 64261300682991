
export const persistString = (key, value) => {    
    try {
        localStorage.setItem(key, value);
    } catch (e) {
        console.log('Error saving to local storage', e);
    }    
};

export const deleteString = (key) => {    
    try {
        localStorage.removeItem(key);
    } catch (e) {
        console.log('Error deleting from local storage', e);
    }    
};

export const getString = (key) => {
    return localStorage.getItem(key);
}