
import { IconButton, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import SettingsIcon from "@material-ui/icons/Settings";
import ScreenComponent from "./ScreenComponent";
import QuestionDialog from "../../question/component/QuestionDialog";

const ScreenQuestionComponent = (props) => {
  const component = props.component;
  const language = props.language;  
  const [questionText, setQuestionText] = useState('');
  const [isEditing, setIsEditing] = useState(component.question ? false : true);
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);

  useEffect(() => {
    setQuestionText(component.question ? (component.question[language] ? component.question[language] : '' ) : '');
  }, [props.language]);
  
  const onConfirm = () => {
    var updatedQuestion = {
      ...(component.question ? component.question : {})
    };
    updatedQuestion[language] = questionText;

    //TODO: provide default values - maybe a screen component model factory?
    props.onSave({
      ...component,
      question: updatedQuestion,
      answer: component.answer ? component.answer : '',
      points: component.points ? component.points: 100,
      hints: component.hints ? component.hints: [],
      incorrectDeductionPoints: component.incorrectDeductionPoints ? component.incorrectDeductionPoints : 0,
    });

    setIsEditing(false);
  }

  const openSettings = () => {
    setIsSettingsDialogOpen(true);
  }

  const onSaveSettings = (component) => {
    props.onSave(component);
  }

  return (
      <ScreenComponent className="screen-question-component" component={props.component} onDelete={props.onDelete} type="Question">
      { isEditing 
        ? <TextField variant="outlined" label="Text" value={questionText} onChange={(e) => setQuestionText(e.target.value)} onKeyPress={(e) => {if(e.key === 'Enter') onConfirm()}} autoFocus/>
        : <div className="screen-question-component-content">
            <p>Question: {questionText}</p> 
            <IconButton onClick={(e) => setIsEditing(true)}><EditIcon /></IconButton>
            { component.question != null && <IconButton onClick={(e) => openSettings()}><SettingsIcon /></IconButton>}
          </div>
      }
      {isSettingsDialogOpen && <QuestionDialog component={component} onClose={() => setIsSettingsDialogOpen(false)} onSave={onSaveSettings} languages={props.languages}/>}
      </ScreenComponent>
  )

}


const mapStateToProps = (state, ownProps) => ({
  languages: state.challenge.currentChallenge.customer.availableLanguages,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ScreenQuestionComponent);